import React, { useRef, useState } from 'react'
import { Button, Checkbox, Form, Input, message } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import './user.css'
import { userLogin } from '../../services/api';
import { useNavigate } from 'react-router-dom'


function setCookie(data) {
    // 设置一个具体的日期作为过期时间
    let date = new Date(new Date().getTime() + data.expires)
    // 完成cookie最基本保存，后面跟的是cookie的过期时间
    let cookie = `${data.name}=${data.value};data.expires=${date.toGMTString()}`
    document.cookie = cookie
}


export default function WebConsole (props) {

    const navigate = useNavigate()

    const onFinish = (values: any) => {
        console.log('Received values of form: ', values);
        
        userLogin({ userPhone: values.username, userPassword: values.password }).then((res)=>{
          console.log('res', res)
          if (res.errorCode === "OK") {
            message.success('登录成功')
            localStorage.setItem('userInfo', JSON.stringify(res.data[0]))
            setCookie({
                name: 'token',
                value: res.data[0].token,
                expires: 1,            
            })
            navigate('/Home')
          } else {
            message.error('登录失败')
          }
        })
    };

    return(
        <div className='user'>
            <div className='user-content'>
                <span className='user-title'>Web-Console 登录</span>
                <Form
                    name="normal_login"
                    className="login-form"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    >
                    <Form.Item
                        name="username"
                        rules={[{ required: true, message: '请输入您的用户名！' }]}
                    >
                        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: '请输入您的密码！' }]}
                    >
                        <Input
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        type="password"
                        placeholder="Password"
                        />
                    </Form.Item>
                    <Form.Item>
                        <Form.Item name="remember" valuePropName="checked" noStyle>
                        <Checkbox>Remember me</Checkbox>
                        </Form.Item>

                        {/* <a className="login-form-forgot" href="">
                        Forgot password
                        </a> */}
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="login-form-button">
                        登录
                        </Button>
                        {/* Or <a href="">register now!</a> */}
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}
