export const instructionsList = [
    {
        name: '默认分组',
        childList: [
            {
                key: '1',
                name: '温度',
                value: '0A 04 00 00 00 02 70 B0',
                delay: 1000,
                repeat: 1,
                isHex: false,
            },
            {
                key: '2',
                name: '温度(加校验)',
                value: '0A 04 00 00 00 02',
                delay: 1000,
                repeat: 1,
                isHex: false,
            },
            {
                key: '3',
                name: 'test',
                value: 'test',
                delay: 1000,
                repeat: 1,
                isHex: false,
            },
            {
                key: '4',
                name: '湿度',
                value: '11 22 33 44 55 66',
                delay: 1000,
                repeat: 1,
                isHex: false,
            },
            {
                key: '5',
                name: '11',
                value: '333',
                delay: 1000,
                repeat: 1,
                isHex: false,
            },
            {
                key: '6',
                name: '',
                value: '33 44 55 77 88',
                delay: 1000,
                repeat: 1,
                isHex: false,
            },
            {
                key: '7',
                name: '9090',
                value: '23456',
                delay: 1000,
                repeat: 1,
                isHex: false,
            },
                                                                      
        ]
    }
]