export const highlightColorList = [
    {
        name: '默认分组',
        childList: [
            {
                id: '',
                highlightkey: 'error',
                colorName: '红色',
                color: '#FF0000',
                checked: false,
            },
            {
                id: '',
                highlightkey: 'waning',
                colorName: '蓝色',
                color: '#0000FF',
                checked: false,
            },
            {
                id: '',
                highlightkey: 'test',
                colorName: '绿色',
                color: '#008000',
                checked: false,
            },
            {
                id: '',
                highlightkey: 'app',
                colorName: '黄色',
                color: '#FFFF00',
                checked: false,
            },
            {
                id: '',
                highlightkey: 'test1',
                colorName: '青色',
                color: '#00FFFF',
                checked: false,
            },
            {
                id: '',
                highlightkey: 'test2',
                colorName: '深灰色',
                color: '#A9A9A9',
                checked: false,
            },
            {
                id: '',
                highlightkey: 'test3',
                colorName: '棕色',
                color: '#A52A2A',
                checked: false,
            },
        ]
    },
]