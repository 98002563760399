export const leftOptions = [
    {
        name: '波特率',
        value: 'baudRate',
        default: 115200,
        optionList: [ 
            {value: 9600, label: '9600'},
            {value: 115200, label: '115200'},
            {value: 110, label: '110'},
            {value: 300, label: '300'},
            {value: 600, label: '600'},
            {value: 1200, label: '1200'},
            {value: 2400, label: '2400'},
            {value: 4800, label: '4800'},
            {value: 7200, label: '7200'},
            {value: 14400, label: '14400'},
            {value: 19200, label: '19200'},
            {value: 28800, label: '28800'},
            {value: 38400, label: '38400'},
            {value: 56000, label: '56000'},
            {value: 57600, label: '57600'},
            {value: 76800, label: '76800'},
            {value: 230400, label: '230400'},
            {value: 256000, label: '256000'},
            {value: 460800, label: '460800'},
            {value: 500000, label: '500000'},
            {value: 512000, label: '512000'},
            {value: 600000, label: '600000'},
            {value: 750000, label: '750000'},
            {value: 921600, label: '921600'},
            {value: 1000000, label: '1000000'},
            {value: 1500000, label: '1500000'},
            {value: 2000000, label: '2000000'},
        ]
    },
    {
        name: '数据位',
        value: 'dataBits',
        default: 8,
        optionList: [ {value: 5, label: '5'}, {value: 6, label: '6'}, {value: 7, label: '7'}, {value: 8, label: '8'} ]
    },
    {
        name: '停止位',
        value: 'stopBits',
        default: 1,
        optionList: [ {value: 1, label: '1'}, {value: 1.5, label: '1.5'}, {value: 2, label: '2'} ],
    },
    {
        name: '校验位',
        value: 'parity',
        default: 'none',
        optionList: [ {value: 'none', label: 'None'}, {value: 'even', label: 'Even'}, {value: 'odd', label: 'Odd'} ],
    },
    // {
    //     name: '缓冲区',
    //     default: 1024,
    //     optionList: [ 
    //         {value: 255, label: '255'}, 
    //         {value: 512, label: '512'},
    //         {value: 1024, label: '1024'}, 
    //         {value: 2048, label: '2048'},
    //         {value: 4096, label: '4096'}, 
    //         {value: 8192, label: '8192'}
    //     ]
    // },
    {
        name: '流控制',
        value: 'flowControl',
        default: 'none',
        optionList: [ {value: 'none', label: 'None'}, {value: 'hardware', label: 'HardWare'} ],
    },
]