import axios from 'axios'


// 登录接口
export const userLogin = (params) => axios.post('/user/login', null, { 
    params: params 
});


// 获取设备列表
export const getDeviceData = (params) => axios.get('/deviceData/11/49', { 
    params: params 
});


// export const userLogin = data => axios.post('/user/login', data);


// https://apitest.lggs.tech/user/login?userPhone=17611616120&userPassword=123456