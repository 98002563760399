import React, { useState, useEffect } from 'react'
import { Dropdown, Menu, Input, Checkbox, Radio, message, Modal, Space, Select} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import './Hearder.css'
import { useNavigate } from 'react-router-dom'


function getCookie(name) {
    const cookieString = document.cookie;
    const cookies = cookieString.split('; ');
    for (const cookie of cookies) {
      const [cookieName, cookieValue] = cookie.split('=');
      if (cookieName === name) {
        return cookieValue;
      }
    }
    return null;
  }


  const backgroundList = [
    {
        label: '黑色',
        value: '#333'
    },
    {
        label: '白色',
        value: '#FFF'
    },
    {
        label: '灰色',
        value: '#A9A9A9'
    },
    {
        label: '绿色',
        value: '#00FA9A'
    },
    {
        label: '蓝色',
        value: '#AEEEEE'
    },
  ]

  const fontColorList = [
    {
        label: '黑色',
        value: '#333'
    },
    {
        label: '白色',
        value: '#FFF'
    },
    {
        label: '红色',
        value: '#FF0000'
    },
    {
        label: '蓝色',
        value: '#0000FF'
    },
    {
        label: '黄色',
        value: '#cccc02'
    },

  ]

  const fontSizeList = [
    {
        label: '12',
        value: '12'
    },
    {
        label: '14',
        value: '14'
    },
    {
        label: '16',
        value: '16'
    },
    {
        label: '18',
        value: '18'
    },
    {
        label: '20',
        value: '20'
    },
    {
        label: '22',
        value: '22'
    },
    {
        label: '24',
        value: '24'
    },
    {
        label: '26',
        value: '26'
    },
    {
        label: '28',
        value: '28'
    },
    {
        label: '30',
        value: '30'
    },
    {
        label: '32',
        value: '32'
    },
    {
        label: '34',
        value: '34'
    },
    {
        label: '36',
        value: '36'
    },
  ]

 
function Highlight () {

    const navigate = useNavigate()

    const [ userName, setuserName ] = useState('')
    const [ saveOpen, setSaveOpen ] = useState(false)
    const [ background, setBackground ] = useState('')
    const [ fontColor, setFontColor ] = useState('')
    const [ fontSize, setFontSize ] = useState('22')


    useEffect(()=>{

        const userInfo = getCookie('token')
        console.log('0000', userInfo)

        let userData = JSON.parse(localStorage.getItem('userInfo'))

        if (userData) {
            setuserName(userData.userName)
            console.log('7890-', userData)
        }

    }, [])

    useEffect(()=>{

        let shiLiBox = document.getElementById('shiLiBox')
        if (shiLiBox) {
            shiLiBox.style.background = background
            document.getElementById('shiLiFont').style.color = fontColor
            document.getElementById('shiLiFont').style.fontSize = fontSize + 'px'
        }

    }, [ background, fontColor, fontSize ])

    // 退出登录 
    const confirm = ()=>{
        Modal.confirm({
            title: "退出登录",
            content: '确定要退出登录吗？',
            okText: "确定",
            cancelText: "取消",
            onOk: () => {
                // localStorage.removeItem('userName')
                // localStorage.removeItem('roleId')
                localStorage.removeItem('userInfo')
                const expirationDate = new Date();
                expirationDate.setDate(expirationDate.getDate() - 1);
                document.cookie = `token=; expires=${expirationDate.toUTCString()}`;
                navigate('/user')
            }
        })
    }

    // 跳转到登录页面
    const toUser = ()=>{
        navigate('/user')
    }

    const SaveOpenChange = ()=>{

        setSaveOpen(true)

        let fontBackground = JSON.parse(localStorage.getItem('FontBackground'))

        let shiLiBox = document.getElementById('shiLiBox')
        if (shiLiBox) {
            shiLiBox.style.background = fontBackground.background
            document.getElementById('shiLiFont').style.color = fontBackground.fontColor
            document.getElementById('shiLiFont').style.fontSize = fontBackground.fontSize + 'px'
        }
        setBackground(fontBackground.background)
        setFontColor(fontBackground.fontColor)
        setFontSize(fontBackground.fontSize)


    }


    const items = [
        {
            key: '1',
            label: (
            <div onClick={SaveOpenChange}>
                日志窗口配置
            </div>
            ),
        },
        {
            key: '2',
            label: '日志窗口主题',
            children: [
                {
                  key: '2-1',
                  label: (
                    <div onClick={() => {
                        setBackground('#000')
                        setFontColor('#fff')
                        setFontSize('14')   
                        const fontBackgroundList =  {
                            background: '#000',
                            fontColor: '#fff',
                            fontSize: '14'
                        }
                        localStorage.setItem('FontBackground', JSON.stringify(fontBackgroundList))
                        let serialLogs = document.getElementById('serial-logs')
                        serialLogs.style.background = fontBackgroundList.background
                        serialLogs.style.color = fontBackgroundList.fontColor
                        serialLogs.style.fontSize = fontBackgroundList.fontSize + 'px'             
                    }}>
                        黑色经典（黑底白字）
                    </div>
                  ),
                },
                {
                  key: '2-2',
                  label: (
                    <div onClick={() => {
                        setBackground('#fff')
                        setFontColor('#000')
                        setFontSize('14') 
                        const fontBackgroundList =  {
                            background: '#fff',
                            fontColor: '#000',
                            fontSize: '14'
                        }
                        localStorage.setItem('FontBackground', JSON.stringify(fontBackgroundList))
                        let serialLogs = document.getElementById('serial-logs')
                        serialLogs.style.background = fontBackgroundList.background
                        serialLogs.style.color = fontBackgroundList.fontColor
                        serialLogs.style.fontSize = fontBackgroundList.fontSize + 'px'
                    }}>
                        白色经典（白底黑字）
                    </div>
                  ),
                },
            ],
        },
        {
            key: '3',
            label: '帮助'
        }
    ]

    const saveCancel = ()=>{
        setSaveOpen(false)
    }

    const saveOk = ()=>{

        const fontBackgroundList =  {
            background: background,
            fontColor: fontColor,
            fontSize: fontSize
        }
        localStorage.setItem('FontBackground', JSON.stringify(fontBackgroundList))
        let serialLogs = document.getElementById('serial-logs')
        serialLogs.style.background = fontBackgroundList.background
        serialLogs.style.color = fontBackgroundList.fontColor
        serialLogs.style.fontSize = fontBackgroundList.fontSize + 'px'

        setSaveOpen(false)
    }

    const backgroundChange = (value)=>{
        setBackground(value)
    }

    const fontColorChange = (value)=>{
        setFontColor(value)
    }

    const fontSizeChange = (value)=>{
        setFontSize(value)
    }

    return(
        <div className="header">
            <h1>Web-Console</h1>
            <Dropdown menu={{ items }}>
                <div className='shezhi'>
                <Space>
                    设置
                    <DownOutlined />
                </Space>
                </div>
            </Dropdown>
            <div className='userInfo'>
                {
                userName ? 
                <Dropdown
                    overlay={
                        <Menu>
                            <Menu.Item>
                                <div className=''>
                                    <span onClick={confirm}>退出登录</span>
                                </div>
                            </Menu.Item>
                        </Menu>
                    }
                >
                    <div className='user-title'>
                        <span>{userName}</span>
                    </div>
                </Dropdown>
                :
                <div className='user-title'>
                    <span onClick={toUser}>登录</span>
                </div>
                }
            </div>
            {/* 日志窗口配置  */}
            <Modal title="日志窗口配置" cancelText='取消' okText='确定' destroyOnClose='true' open={saveOpen} onOk={saveOk} onCancel={saveCancel}>
                <span>示例：</span>
                <div className='shiLiBox' id='shiLiBox'>
                    <span className='shiLiFont' id='shiLiFont'> Hello World </span>
                </div>
                <div>
                    <span>窗口背景：</span>
                    <Select 
                        defaultValue={background} 
                        style={{ width: '200px', marginBottom: '20px' }}
                        onChange={backgroundChange} 
                        options={backgroundList}
                    />
                </div>
                <div>
                    <span>字体颜色：</span>
                    <Select 
                        defaultValue={fontColor} 
                        style={{ width: '200px', marginBottom: '20px' }}
                        onChange={fontColorChange} 
                        options={fontColorList}
                    />
                </div>
                <div>
                    <span>字体大小：</span>
                    <Select 
                        defaultValue={fontSize} 
                        style={{ width: '200px', marginBottom: '20px' }}
                        onChange={fontSizeChange} 
                        options={fontSizeList}
                    />
                    <span>(px)</span>
                </div>
            </Modal>
        </div>
    )
}

export default Highlight