import React, { useRef, useState, useEffect } from 'react'
import './webConsole.css'
import { Col, Row, Select, Button, Input, Radio, Checkbox, message, Tabs, Modal } from 'antd';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import { leftOptions } from '../../common/leftOptions'
import { formatDate } from '../../utils/time'
import { saveAs } from '../../utils/save'
import { calculateCRC16Modbus } from '../../utils/crc16'
import Highlight from '../../compontens/Highlight/Highlight';
import ShortcutInstructions from '../../compontens/ShortcutInstructions/shortcutInstructions'
import Header from '../../compontens/Hearder/Hearder';
import CloudsView from '../../compontens/CloudsView/CloudsView'

const { TextArea } = Input;
// const [messageApi] = message.useMessage();

let reader = null 
let serialData = []  //串口缓存数据
let asciidecoder = new TextDecoder()
let serialLogs = null // 串口日志窗口
let serialTimer = null // 串口分包合并时钟
let serialloopSendTimer = null // 串口循环发送时钟
//左侧串口配置数据
const leftSelect = {
  baudRate:9600, //波特率
  dataBits: 8, //数据位
  stopBits: 1,//停止位
  parity: 'none',//校验位
  bufferSize: 1024,//缓冲区
  flowControl: 'none',//流控制
}
// 工具配置
const toolOptions = {
  //自动滚动
  autoScroll: true,
  //显示时间 界面未开放
  showTime: true,
  //日志类型
  logType: 'hex&ascii',
  //分包合并时间
  timeOut: 50,
  //末尾加回车换行
  addCRLF: false,
  //HEX发送
  hexSend: false,
  //循环发送
  loopSend: false,
  //循环发送时间
  loopSendTime: 1000,
  //输入的发送内容
  sendContent: '',
  //快捷发送选中索引
  quickSendIndex: 0,
  //是否CRC校验
  crcCheck: false
}

let keyWordHighlight = []
let colorHighlight = ''
let keyWordHighlightList = []
let isHighlight = false

const highlightFun = (keyList, is)=>{
  keyWordHighlightList = keyList
  isHighlight = is
}

const hasNewLine = (str) => {
  var regex = /\n/; // 定义正则表达式，\n表示换行符
  return regex.test(str); // test()函数返回true或false，根据结果进行判断
}
// const tabItem = [
//   {
//     key: '1',
//     label: '高亮配置',
//     children: <Highlight highlightFun={highlightFun}/>,
//   },
//   {
//     key: '2',
//     label: '快捷指令',
//     children: <ShortcutInstructions/>,
//   }
// ]

let saveName = '' //导出文件名字
let saveFormat = 'log' // 导出格式

export default function WebConsole () {

    //左侧串口配置数据
    // const [ leftSelect, setLeftSelect ] = useState({ 
    //                                                 baudRate:9600, //波特率
    //                                                 dataBits: 8, //数据位
    //                                                 stopBits: 1,//停止位
    //                                                 parity: 'none',//校验位
    //                                                 bufferSize: 1024,//缓冲区
    //                                                 flowControl: 'none',//流控制
    //                                                 })
    // 工具配置
    // const [ toolOptions, setToolOptions ] = useState({ 
    //                                                 //自动滚动
    //                                                 autoScroll: true,
    //                                                 //显示时间 界面未开放
    //                                                 showTime: true,
    //                                                 //日志类型
    //                                                 logType: 'hex&ascii',
    //                                                 //分包合并时间
    //                                                 timeOut: 50,
    //                                                 //末尾加回车换行
    //                                                 addCRLF: false,
    //                                                 //HEX发送
    //                                                 hexSend: false,
    //                                                 //循环发送
    //                                                 loopSend: false,
    //                                                 //循环发送时间
    //                                                 loopSendTime: 1000,
    //                                                 //输入的发送内容
    //                                                 sendContent: '',
    //                                                 //快捷发送选中索引
    //                                                 quickSendIndex: 0,
    //                                                 //是否CRC校验
    //                                                 crcCheck: false
    //                                             })
    const [ isLeftDiv, setIsLeftDiv ] = useState(false) // 左边配置收起
    const [ isRightDiv, setIsRightDiv ] = useState(false) // 左边配置收起
    const [ serialPort, setSerialPort ] = useState(null)
    const [ serialOpen, setSerialOpen ] = useState(false) //串口开启状态
    const [ serialClose, setSerialClose ] = useState(true) //串口目前是手动关闭状态
    // const [ keyWordHighlight, setKeyWordHighlight ] = useState('') //高亮关键字
    // const [ isHighlight, setIsHighlight ] = useState(false) // 高亮开关
    const [ saveOpen, setSaveOpen ] = useState(false) // 导出下载弹窗

    const serialOpenRef = useRef(serialOpen)
    serialOpenRef.current = serialOpen


    const instructionsSend = (data)=>{
      if (data.isHex) {
        sendHex(data.value)
      } else {
        sendText(data.value)
      }
    }

    const tabItem = [
      {
        key: '1',
        label: '高亮配置',
        children: <Highlight highlightFun={highlightFun}/>,
      },
      {
        key: '2',
        label: '快捷指令',
        children: <ShortcutInstructions instructionsSend={instructionsSend}/>,
      }
    ]
    
    
    console.log(serialClose, isHighlight)
    if (!('serial' in navigator)) {
        alert('当前浏览器不支持串口操作,请更换Edge或Chrome浏览器')
    }

    navigator?.serial.getPorts().then((ports) => {
      console.log('ports', ports)
        if (ports.length > 0) {
          setSerialPort(ports[0])
          console.log('11-22-33', serialPort)
          serialStatuChange(true)
        }
    })
  
    serialLogs = document.getElementById('serial-logs')


    useEffect(()=>{


      let fontBackground = JSON.parse(localStorage.getItem('FontBackground'))
      let serialLogs = document.getElementById('serial-logs')
      if (fontBackground) {
        
        serialLogs.style.background = fontBackground.background
        serialLogs.style.color = fontBackground.fontColor
        serialLogs.style.fontSize = fontBackground.fontSize + 'px'
        
      } else {

        serialLogs.style.background = '#000'
        serialLogs.style.color = '#fff'
        serialLogs.style.fontSize = '14px'

      }

    }, [])

    // 日志类型修改
    const logChange = (value) => {
        console.log('value', value)
        changeOption('logType', value)
      }
      
     const serialSelectChange = (value, option, name) => {
        console.log('value', value, option, name)
        leftSelect[name] = value
        console.log('00000-', leftSelect)
      }

      // 末尾加回车换行开关
      const addCRLFChange = (e)=>{
        changeOption('addCRLF', e.target.checked)
      }

      // HEX发送开关
      const hexSendChange = (e)=>{
        changeOption('hexSend', e.target.checked)
      }

      // 循环发送开关
      const loopSendChange = async (e)=>{
        await changeOption('loopSend', e.target.checked)
        resetLoopSend()
      }

      // 修改循环发送时间
      const sendTimeChange = (e)=>{
        changeOption('loopSendTime', parseInt(e.target.value))
      }

      // 日志窗口右上角选择
      const logTopOption = (e)=>{
        console.log('e.target.value', e.target.value)
      }

      // 分包超时修改
      const timeOutChange = (e)=>{
        console.log('v', e.target.value)
        changeOption('timeOut', parseInt(e.target.value))
      }

      // 选择串口
      const serialSelectPort = async () => {
        try {
          await navigator.serial.requestPort().then(async (port) => {
            //关闭旧的串口
            console.log('serialPort', port)

            // this.serialPort?.close()
            await serialPort?.forget()
            setSerialPort(port)
            serialStatuChange(true)
          })
        } catch (e) {
          console.error('获取串口权限出错' + e.toString())
        }
      }

      // 打开关闭串口
      const serialOpenClose = () => {
        if (!serialPort) {
          message.warning('请先选择串口');
          return
        }
        if (serialPort?.writable && serialPort?.readable) {
          closeSerial()
          setSerialClose(true)
          return
        }
        console.log('11-22', serialPort)
        openSerial()
      }

      // 关闭串口
      const closeSerial = () =>  {
        if (serialOpen) {
          setSerialOpen(false)
          console.log('23', reader)
          reader?.cancel()
        }
      }

      const openSerial = () =>  {
        console.log('------', leftSelect)
        let SerialOptions = {
          baudRate: parseInt(leftSelect.baudRate),
          dataBits: parseInt(leftSelect.dataBits),
          stopBits: parseInt(leftSelect.stopBits),
          parity: leftSelect.parity,
          bufferSize: parseInt(leftSelect.bufferSize),
          flowControl: leftSelect.flowControl,
        }
  
        serialPort?.open(SerialOptions)
          .then(() => {
            setSerialOpen(true)
            setSerialClose(false)
            localStorage.setItem('serialOptions', JSON.stringify(SerialOptions))
            setTimeout(() => {
              readData()
            }, 1000);
          })
          .catch((e) => {
            message.warning('打开串口失败:' + e.toString());
          })
      }

      // 设备状态改变
      const serialStatuChange = (statu) => {
        let tip
        if (statu) {
          tip = '<div>设备已连接</div>'
          document.getElementById('serial-status').style.backgroundColor = '#d1e7dd'
        } else {
          tip = '<div>设备已断开</div>'
          document.getElementById('serial-status').style.backgroundColor = '#f8d7da'
        }
        document.getElementById('serial-status').innerHTML = tip
      }

      // 读串口数据
      const readData = async () =>  {
        console.log('0000000', serialOpen, serialOpenRef.current, serialPort)
        while (serialOpenRef.current && serialPort?.readable) {
          reader = serialPort?.readable.getReader()
          try {
              const { value, done } = await reader?.read()
              console.log('999-读', reader, value, done)
              if (done) {
                break
              }
              dataReceived(value)
          } finally {
            console.log('9', )
            reader?.releaseLock()
          }
        }
        await serialPort?.close()
      }

      //串口分包合并
      const dataReceived = async (data) => {
        serialData = [ ...serialData, ...data ]
        if (toolOptions.timeOut === 0) {
            addLog(serialData, true)
            serialData = []
            return
          }
          //清除之前的时钟
          clearTimeout(serialTimer)
          console.log('6-6', serialTimer)
          serialTimer = setTimeout(() => {
            //超时发出
            addLog(serialData, true) 
            serialData = []
          }, toolOptions.timeOut)

      }

      //添加日志
      const addLog = (data, isReceive = true) => {
        let classname = 'text-primary'
        let form = '[ Tx ] '
        if (isReceive) {
          classname = 'text-success'
          form = '[ Rx ] '
        }
        let newmsg = ''
        if (toolOptions.logType.includes('hex')) {
          let dataHex = []
          for (const d of data) {
            //转16进制并补0
            dataHex.push(('0' + d.toString(16).toLocaleUpperCase()).slice(-2))
          }
          if (toolOptions.logType.includes('&')) {
            newmsg += 'HEX: '
          }
          console.log('dataHex', dataHex)
          if(toolOptions.crcCheck) {            
            let text = dataHex.join(' ').replace(/\s+/g, "");
            let crcText = calculateCRC16Modbus(text)
            let crcList = crcText.split('')
            console.log('crcText', text, crcText, crcList)
            let crc1 = crcList[0] + crcList[1]
            let crc2 = crcList[2] + crcList[3]
            dataHex.push(crc2)
            dataHex.push(crc1)

          }
          newmsg += dataHex.join(' ')
          if (isHighlight) {
            // let text = dataHex.join(' ').replace(/\s+/g, "");
            // eslint-disable-next-line
            // let reg = eval("/" + keyWordHighlight + "/g")
            // let div = `<span style="background:${colorHighlight}">${newmsg}</span>`
            // if(newmsg.includes(keyWordHighlight)){
            //   newmsg = div
            // }
            if (hasNewLine(newmsg)) {
              let lines = newmsg.split(/\r?\n/);
              lines.forEach((line, index)=>{
                keyWordHighlightList.forEach(item => {
                  if(line.includes(item.highlightkey)){
                    
                    let div = `<span style="background:${item.color}">${line}</span>`
                    console.log('进来了', div)
                    lines[index] = div
                  }
                });
              })
              console.log('处理过的高亮', lines)
              newmsg = lines.join("\n")
            } else {
              console.log('高亮', newmsg, dataHex )
              keyWordHighlightList.forEach(item => {
                if(newmsg.includes(item.highlightkey)){
                  let div = `<span style="background:${item.color}">${newmsg}</span>`
                  newmsg = div
                }
              });
              console.log('hig', newmsg)  
            }
          }
        }
        if (toolOptions.logType.includes('ascii')) {
          let dataAscii = asciidecoder.decode(Uint8Array.from(data))
          if (toolOptions.logType.includes('&')) {
            newmsg += '   TXT: '
            // let time = toolOptions.showTime ? formatDate(new Date()) + '&nbsp;' : ''
            // newmsg += '<span class="' + classname + '">' + form + time + '</span>' + 'TXT: '
           }
          newmsg += dataAscii
          if (isHighlight) {
            // eslint-disable-next-line
            // let reg = eval("/" + keyWordHighlight + "/g")
            // let div = `<span style="background:${colorHighlight}">${newmsg}</span>`
            // if(newmsg.includes(keyWordHighlight)){
            //   newmsg = div
            // }
            if (hasNewLine(newmsg)) {
              let lines = newmsg.split(/\r?\n/);
              lines.forEach((line, index)=>{
                keyWordHighlightList.forEach(item => {
                  if(line.includes(item.highlightkey)){
                    
                    let div = `<span style="background:${item.color}">${line}</span>`
                    console.log('进来了', div)
                    lines[index] = div
                  }
                });
              })
              console.log('处理过的高亮', lines)
              newmsg = lines.join("\n")
            } else {
              console.log('高亮', newmsg, dataAscii )
              keyWordHighlightList.forEach(item => {
                if(newmsg.includes(item.highlightkey)){
                  let div = `<span style="background:${item.color}">${newmsg}</span>`
                  newmsg = div
                }
              });
              console.log('hig', newmsg)
            }
          }
        }
        let time = toolOptions.showTime ? formatDate(new Date()) + '&nbsp;' : ''
        let template = ''
        if (hasNewLine(newmsg)) { 
          let lines = newmsg.split(/\r?\n/);
          lines.forEach((line, index)=>{
            lines[index] = '<span class="' + classname + '">' +form + time + '</span>' + line
          })
          newmsg = lines.join("\n")
          template = '<div>' + newmsg + '</div>'
        } else {
          template = '<div><span class="' + classname + '">' + form + time + '</span>' + newmsg + '</div>'
        }
        let tempNode = document.createElement('div')
        tempNode.innerHTML = template
        console.log('serialLogs', serialLogs)
        // let logs = document.getElementById('serial-logs')
        serialLogs?.append(tempNode)
        if (toolOptions.autoScroll) {
          document.getElementById('serial-logs').scrollTop = serialLogs?.scrollHeight - serialLogs?.clientHeight
        }
      }

      // 添加云端日志
      const addCloudLog = (data, times)=>{
        let classname = 'text-success'
        let form = '[ Rx ] '
        let newmsg = ''
        let dataAscii = data
        newmsg += dataAscii
        if (isHighlight) {
          // eslint-disable-next-line
          // let reg = eval("/" + keyWordHighlight + "/g")
          // let div = `<span style="background:${colorHighlight}">${newmsg}</span>`
          // if(newmsg.includes(keyWordHighlight)){
          //   newmsg = div
          // }
          if (hasNewLine(newmsg)) {
            let lines = newmsg.split(/\r?\n/);
            lines.forEach((line, index)=>{
              keyWordHighlightList.forEach(item => {
                if(line.includes(item.highlightkey)){
                  
                  let div = `<span style="background:${item.color}">${line}</span>`
                  console.log('进来了', div)
                  lines[index] = div
                }
              });
            })
            console.log('处理过的高亮', lines)
            newmsg = lines.join("\n")
          } else {
            console.log('高亮', newmsg, dataAscii )
            keyWordHighlightList.forEach(item => {
              if(newmsg.includes(item.highlightkey)){
                let div = `<span style="background:${item.color}">${newmsg}</span>`
                newmsg = div
              }
            });
            console.log('hig', newmsg)
          }
        }
        let time = times ? formatDate(new Date(parseInt(times) * 1000)) + '&nbsp;' : formatDate(new Date()) + '&nbsp;'
        const template = '<div><span class="' + classname + '">' + form + time + '</span>' + newmsg + '</div>'
        let tempNode = document.createElement('div')
        tempNode.innerHTML = template
        console.log('serialLogs', serialLogs)
        // let logs = document.getElementById('serial-logs')
        serialLogs = document.getElementById('serial-logs')
        serialLogs?.append(tempNode)
        if (toolOptions.autoScroll) {
          document.getElementById('serial-logs').scrollTop = serialLogs?.scrollHeight - serialLogs?.clientHeight
        }
      }

      // //串口数据收发
      const send = async () =>  {
        let content = document.getElementById('serial-send-content').value
        if (!content) {
          message.warning('发送内容为空');
          return
        }
        if (toolOptions.hexSend) {
          await sendHex(content)
        } else {
          await sendText(content)
        }
      }

      // 发送HEX到串口
      const sendHex = async (hex) => {
        const value = hex.replace(/\s+/g, '')
        console.log('hex', hex, hex.replace(/\s+/g, ''))
        if (/^[0-9A-Fa-f]+$/.test(value) && value.length % 2 === 0) {
          let data = []
          for (let i = 0; i < value.length; i = i + 2) {
            data.push(parseInt(value.substring(i, i + 2), 16))
          }
          await writeData(Uint8Array.from(data))
        } else {
          addLogErr('HEX格式错误:' + hex)
        }
      }

      // 发送ASCII到串口
      const sendText = async (text) => {
        const encoder = new TextEncoder()
        writeData(encoder.encode(text))
      }

      //写串口数据
      const writeData = async (data) => {
        console.log('dak', serialPort, data)
        if (!serialPort || !serialPort.writable) {
          addLogErr('请先打开串口再发送数据')
          return
        }
        const writer = serialPort.writable.getWriter()
        if (toolOptions.addCRLF) {
          data = new Uint8Array([...data, 0x0d, 0x0a])
          console.log('dak', data)
        }
        await writer.write(data)
        console.log('8')
        writer.releaseLock()
        addLog(data, false)
      }

      // 错误系统日志
      const addLogErr = (msg) => {
        let time = toolOptions.showTime ? formatDate(new Date()) + '&nbsp;' : ''
        const template = '<div><span class="text-danger">' + time + ' 系统消息:</span> ' + msg + '</div>'
        let tempNode = document.createElement('div')
        tempNode.innerHTML = template
        console.log('serialLogs-f', serialLogs)
        let logs = document.getElementById('serial-logs')
        logs?.append(tempNode)
        if (toolOptions.autoScroll) {
            logs.scrollTop = serialLogs?.scrollHeight - serialLogs?.clientHeight
        }
      }

      // 自动滚动
      const automaticChange = (e)=>{
        let autoScroll = e.target.checked
        changeOption('autoScroll', autoScroll)
      }

      // 清空日志
      const serialClear = () => {
        // this.setState({
        //     serialLogs: {
        //         ...this.state.serialLogs,
        //         innerHTML: ''
        //     }
        // })
        document.getElementById('serial-logs').innerHTML = ''
      }

      // 复制日志
      const copyLog = ()=>{
        let text = document.getElementById('serial-logs').innerText
        if (text) {
          let textarea = document.createElement('textarea')
          textarea.value = text
          // textarea?.readOnly = 'readonly'
          textarea.style.position = 'absolute'
          textarea.style.left = '-9999px'
          document.body.appendChild(textarea)
          textarea.select()
          textarea.setSelectionRange(0, textarea.value.length)
          document.execCommand('copy')
          document.body.removeChild(textarea)
          message.success('已复制到剪贴板')
        } else {
          message.warning('日志窗口为空')
        }
      }

      // 导出日志
      const exportLog = ()=>{
        let text = document.getElementById('serial-logs').innerText
        if (text) {
          setSaveOpen(true)
        } else {
          message.warning('日志窗口为空')
        }
      }

      // 日志窗口全屏
      const fullScreen = ()=>{
 
        const elementToFullScreen = document.getElementById('serial-logs'); // 要全屏的元素（这里选取了根节点）
        
        if (elementToFullScreen.requestFullscreen) {
            elementToFullScreen.requestFullscreen();
        } else if (elementToFullScreen.mozRequestFullScreen) { /* Firefox */
            elementToFullScreen.mozRequestFullScreen();
        } else if (elementToFullScreen.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
            elementToFullScreen.webkitRequestFullscreen();
        } else if (elementToFullScreen.msRequestFullscreen) { /* IE/Edge */
            elementToFullScreen.msRequestFullscreen();
        }

      }

      // 修改工具配置
      const changeOption = (key, value) => {
        console.log(key, value)
        // setToolOptions({
        //     ...toolOptions,
        //     [key]: value
        // })
        toolOptions[key] = value
        localStorage.setItem('toolOptions', JSON.stringify(toolOptions))
      }

      // 循环发送
      const resetLoopSend = () => {
        console.log('循环', toolOptions.loopSend)
        clearInterval(serialloopSendTimer)
        if (toolOptions.loopSend) {
          console.log('循环666')
          serialloopSendTimer = setInterval(() => {
            send()
          }, toolOptions.loopSendTime)
        }
      }

      // 是否CRC校验
      const crcCheckChange = (e)=>{
        console.log('pp', e)
        if (e === 'None') {
          changeOption('crcCheck', false)
        } else {
          changeOption('crcCheck', true)
        }
        // changeOption('crcCheck', e.target.checked)
      }

      // 关键字
      const keyWordChange = (e)=>{
        console.log('keyWord', e.target.value)
        keyWordHighlight = e.target.value
      }

      // 高亮
      const heightChange = (e)=>{
        isHighlight = e.target.checked
      }

      const crcJiao = ()=>{
        let logText = document.getElementById('serial-logs').innerHTML
        // let reg = new RegExp("/" + this.state.keyWordHighlight + "/g");
        // eslint-disable-next-line
        let reg = eval("/" + keyWordHighlight + "/g")
        let div = `<span style="color:red">${keyWordHighlight}</span>`
        let aa = logText.replace(reg, div)
        console.log('6666', logText, reg, '----', aa)
        document.getElementById('serial-logs').innerHTML = aa
      }


      const tabChange = () => {

      }

      const saveOk = ()=>{
        if (saveName === '') {
          message.warning('文件名称不能为空')
          return
        }
        let text = document.getElementById('serial-logs').innerText
        let blob = new Blob([text], { type: 'text/plain;charset=utf-8' })
        console.log('text', text, blob)
        let name = saveName + '.' + saveFormat
        saveAs(blob, name)

        setSaveOpen(false)
        saveFormat = 'log'
        saveName = ''
      }

      const saveCancel = ()=>{
        setSaveOpen(false)
        saveFormat = 'log'
        saveName = ''
      }

      const saveNameChange = (e)=>{
        console.log('2222', e.target.value)
        saveName = e.target.value
      }

      const saveOnChange = (e)=>{
        console.log('e--', e.target.value)
        saveFormat = e.target.value
      }


      const leftTabItem = [
        {
          key: '1',
          label: '串口配置',
          children: <div>
                <div id="serial-status" >未选择串口</div>
                {
                    leftOptions.map((item)=>{
                        return (
                        <div className="serial-select" key={item.name}>
                            <span className="option-title">{item.name}</span>
                            <Select 
                                defaultValue={item.default} 
                                style={{ width: '140px' }}
                                onChange={(value, option) => serialSelectChange(value, option, item.value)} 
                                disabled={serialOpen}
                                options={item.optionList}
                            />
                        </div>
                        )
                    })
                }
                <Button style={{marginRight: '10px'}} onClick={serialSelectPort}>选择串口</Button>
                <Button  onClick={serialOpenClose}>{serialOpen ? '关闭串口' : '打开串口'}</Button>
          </div>,
        },
        {
          key: '2',
          label: '云端查看',
          children: <CloudsView addCloudLog={addCloudLog}/>,
        }
      ]
      


  return(
    <div id='webConsole'>
        {/* <div className="header">
            <h1>Web-Console</h1>
        </div> */}
        <Header/>
        <Row>
        {/* 左侧串口配置 */}
            <Col flex="240px" className="serial-left" style={{ display: isLeftDiv ? 'none' : ''}}>
              <Tabs defaultActiveKey="1" items={leftTabItem} onChange={tabChange} />
            </Col>
            <div className='leftDiv' onClick={() => setIsLeftDiv(!isLeftDiv)}>
              <LeftOutlined  style={{ display: isLeftDiv ? 'none' : ''}}/>
              <RightOutlined style={{ display: isLeftDiv ? '' : 'none'}}/>
            </div>
        {/* 串口日志 */}
            <Col flex='auto' className="serial-center">
                <div className="center-title">
                    <h1>串口日志</h1>
                    <div className="center-topOption">
                        <div className="SubcontractingTime">
                            <span>分包超时(ms)</span>
                            <Input  type="number" placeholder="0不分包"  defaultValue="50" style={{width: '80px'}} onChange={timeOutChange}/>
                        </div>
                        <div className="log-select">
                            <span className="log-title">日志类型</span>
                            <Select 
                                defaultValue="hex&ascii" 
                                style={{width: '90px'}}
                                onChange={logChange} 
                                options={[
                                  {value: 'hex&ascii', label: '全部'},
                                  {value: 'hex', label: 'Hex'},
                                  {value: 'ascii', label: 'ASCII'},
                                ]}
                                />
                        </div>
                        <div>
                          <Checkbox defaultChecked ='true' onChange={automaticChange}>自动滚动</Checkbox>
                        </div>
                        <Radio.Group style={{marginTop: '4px'}} onChange={logTopOption}>
                            <Radio.Button value="emptyLog" onClick={serialClear}>清空</Radio.Button>
                            <Radio.Button value="copyLog" onClick={copyLog}>复制</Radio.Button>
                            <Radio.Button value="exportLog" onClick={exportLog}>导出</Radio.Button>
                            {/* <Radio.Button value="UploadLog" onClick={UploadLog}>上传</Radio.Button> */}
                            <Radio.Button value="fullScreen" onClick={fullScreen}>全屏</Radio.Button>
                        </Radio.Group>  
                    </div>
                </div>
                {/* 串口窗口 */}
                <div id="serial-logs" className="serialLogs"></div>
                <div className="sending-option">
                    <TextArea className="sending-textarea" rows={3} id="serial-send-content" style={{resize: 'none'}}
                                placeholder="在此输入要发送的内容,可以是字符串(如:你好,世界!),也可以是HEX(如:49544c4447)"></TextArea>
                    <div className="center-bottom">
                        <div className="send-option">
                            <Checkbox onChange={addCRLFChange}>末尾加回车</Checkbox>  
                            <Checkbox onChange={addCRLFChange}>末尾加换行</Checkbox>  
                            <Checkbox onChange={hexSendChange}>HEX发送</Checkbox>  
                            <Checkbox onChange={loopSendChange}>循环发送</Checkbox>  
                            <div className="SendingInterval">
                                <span>发送间隔(ms)</span>
                                <Input  type="number" defaultValue="1000" style={{width: '100px'}} min="1" onChange={sendTimeChange}/>
                            </div>
                        </div>
                        <div className='check-options'>
                          <span style={{ marginRight: '5px'}}>CRC校验方式</span>
                          <Select 
                              defaultValue='None'
                              style={{width: '160px', marginRight: '10px'}}
                              onChange={crcCheckChange}
                              options={[
                                {value:'None', label: 'None'},
                                {value: 'crc16ModBus', label: 'CRC-16/MODBUS'}
                              ]}
                          />
                          {/* <Checkbox onChange={crcCheckChange}>是否CRC校验</Checkbox>   */}
                          {/* <Input placeholder="关键字" style={{width: '120px', marginRight: '10px'}} onChange={keyWordChange}/>
                          <Checkbox onChange={heightChange}>是否高亮</Checkbox>  
                          <Button onClick={crcJiao}>关键字高亮</Button> */}
                        </div>
                        <Button onClick={send} className='sendBtn' type="primary" style={{ width: '80px'}}>发送</Button>
                    </div>
                </div>
            </Col>
        {/*  右侧串口配置 */}
            <div className='rightDiv' onClick={() => setIsRightDiv(!isRightDiv)}>
              <LeftOutlined  style={{ display: isRightDiv ? '' : 'none'}}/>
              <RightOutlined style={{ display: isRightDiv ? 'none' : ''}}/>
            </div>
            <Col flex="500px" className='serial-right' style={{ display: isRightDiv ? 'none' : ''}}>
              <Tabs defaultActiveKey="1" items={tabItem} onChange={tabChange} />
            </Col>
        </Row>
        {/* 导出下载弹窗  */}
        <Modal title="导出下载格式" cancelText='取消' okText='确定' destroyOnClose='true' open={saveOpen} onOk={saveOk} onCancel={saveCancel}>
            <span>文件名称：</span>
            <Input placeholder="请输入文件名称" style={{width: '185px', marginRight: '210px', marginBottom: '15px', marginTop: '15px'}} onChange={saveNameChange}/>
            <span>导出格式：</span>
            <Radio.Group onChange={saveOnChange}>
              <Radio value='log'>.log</Radio>
              <Radio value='txt'>.txt</Radio>
              <Radio value='doc'>.doc</Radio>
              <Radio value='dat'>.dat</Radio>
            </Radio.Group>
        </Modal>
    </div>
    )

}