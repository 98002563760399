import React, { useState } from 'react'
import './shortcutInstructions.css'
import { Select, Button, Input, Checkbox, Radio, message, Modal, Table, Tooltip, Space, ConfigProvider} from 'antd';
import { CloseOutlined, PlusOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { instructionsList } from '../../common/instructions'


const dataList = localStorage.getItem('shortcutInstructionsList') ? JSON.parse(localStorage.getItem('shortcutInstructionsList')) : instructionsList
const gList = []
dataList.forEach((v, i) => {
    gList.push({
        value: v.name,
        label: v.name
    })
});

let checkList = []

const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        checkList = selectedRows
    },
    getCheckboxProps: (record) => ({
        disabled: record.name === 'Disabled User',
        // Column configuration not to be checked
        name: record.name,
    }),
};

let modalTitle = '新增快捷指令'
let currentIndex = 0
let isbatch = false


function ShortcutInstructions({ instructionsSend }) {

    const [ currentGroup, setCurrentGroup ] = useState('默认分组') // 当前分组
    const [ groupList, setGroupList ] = useState(gList) // 快捷指令分组
    const [ currentInstructionsList, setCurrentInstructionsLis ] = useState(dataList[0].childList) // 当前高亮快捷列表
    const [ instructionsAddList, setInstructionsAddList ] = useState([])
    const [ instructionsOpen, setInstructionsOpen ] = useState(false) // 新增指令弹窗
    const [ instructionsName, setInstructionsName ] = useState('') // 新增指令弹窗指令名称
    const [ instructionsContent, setInstructionsContent ] = useState('') // 新增指令弹窗指令内容
    const [ delayTime, setDelayTime] = useState(1000) // 新增指令弹窗延时时间
    const [ repeatSecond, setRepeatSecond ] = useState(1) // 新增指令弹窗重复次数
    const [groupModalOpen, setGroupModalOpen] = useState(false); //新增分组弹窗
    const [groupDelOpen, setGroupDelOpen] = useState(false); //删除分组弹窗
    const [ groupRenameOpen, setGroupRenameOpen] = useState(false); //重命名分组弹窗
    const [ addGroupInput, setAddGroupInput] = useState('') // 新增分组名字
    const [ isbatchSend, setIsbatchSend ] = useState(false) // 是否批量发送


    const columns = [
        {
          title: '指令',
          dataIndex: 'value',
          width: 110,
        //   ellipsis: {
        //     showTitle: false,
        //   },
        //   render: (text) => <Tooltip placement="topLeft" title={text}>
        //   {text}
        // </Tooltip>,
          ellipsis: true,
        },
        {
          title: '延迟',
          dataIndex: 'delay',
          width:60,
        },
        {
            title: '重复',
            dataIndex: 'repeat',
            width: 40,
        },
        {
            title: 'hex',
            dataIndex: 'isHex',
            width: 35,
            render: (text, record, index) => <Checkbox value={record.checked} onChange={(e)=>{
                console.log('hhh', e.target.checked, text, index)
                const list = currentInstructionsList
                list[index].isHex = e.target.checked
                setCurrentInstructionsLis([ ...list ])
            }}></Checkbox>,
        },
        {
            title: '操作',
            key: 'action',
            render: (_, record, i) => (
              <Space>
                {/* <a>Invite {record.name}</a> */}
                <Button style={{ width: '80px', padding: '4px 8px'}} type='primary' onClick={() => sendS(record)}>
                    {
                        record.name ? 
                        <Tooltip title={record.name}>
                            <span style={{ width: '65px', overflow: 'hidden', textOverflow: 'ellipsis'}}>{record.name}</span>
                        </Tooltip>
                        : '发送'
                    }
                </Button>
                <Button icon={<EditOutlined />} size='small' onClick={()=>{
                  console.log('-----', _, record, i)
                  setInstructionsOpen(true)
                  setInstructionsName(record.name)
                  setInstructionsContent(record.value)
                  setDelayTime(record.delay)
                  setRepeatSecond(record.repeat)
                  modalTitle = '修改快捷指令'
                  currentIndex = i
                }}/>
                <Button icon={<DeleteOutlined />} size='small' onClick={()=>{
                  const list = currentInstructionsList
                  list.splice(i, 1)
                  setCurrentInstructionsLis([ ...list ])
                }}/>
              </Space>
            ),
        },
    ];

    // 快捷指令发送
    const sending = (data)=>{
        return new Promise((reslove) => {
            let num = 0
            let time = setInterval(() => {
                if (isbatch) {
                    instructionsSend(data)
                    num += 1
                    console.log('num', num)
                    if (num === data.repeat) {
                        clearInterval(time)
                        reslove()
                    }
                } else {
                    clearInterval(time)
                }
            }, data.delay);
        })
    }

    const sendS = (data)=>{
            let num = 0
            let time = setInterval(() => {
                    instructionsSend(data)
                    num += 1
                    console.log('num', num)
                    if (num === data.repeat) {
                        clearInterval(time)
                    }
            }, data.delay);
    }


    const batchSend = async ()=>{
      if (!checkList.length) {
        message.warning('请选择要发送的指令')
        return
      }
      if (isbatchSend) {
        setIsbatchSend(false)
        isbatch = false
      } else {
        setIsbatchSend(true)
        isbatch = true
      }
      for (let i = 0; i < checkList.length; i++) {
        if (isbatch) {
            await sending(checkList[i]);
        }
        if (i === checkList.length - 1) {
            setIsbatchSend(false)
            isbatch = false
        }
      }
    //   checkList.forEach( async (v, i)=>{
    //     console.log('v', v)
    //     await sending(v)
    //   })
    }
    

    // 选择分组
    const groupChange = (e)=>{
        setCurrentGroup(e)
        let list = []
        dataList.forEach((v, i)=>{
          if (v.name === e) {
            list = v.childList
          }
        })
        list.forEach((v)=>{
          v.isHex = false
        })
        setCurrentInstructionsLis([ ...list ])
        checkList = []
    }

    // 新增分组
    const addGroup = ()=>{
        setGroupModalOpen(true)
    }

    // 重命名分组
    const renameGroup = ()=>{
        if (currentGroup === '默认分组') {
            message.warning('默认分组不可重命名')
        } else {
            setAddGroupInput(currentGroup)
            setGroupRenameOpen(true)
        }
    }
     
    // 删除分组
    const delGroup = ()=>{
        if (currentGroup === '默认分组') {
            message.warning('默认分组不可删除')
        } else {
            setGroupDelOpen(true)
        }
    }

    // 新增快捷指令
    const addinstructionsList = ()=>{
        // modalTitle = '新增快捷指令'
        setInstructionsOpen(true)
        modalTitle = '新增快捷指令'
    }

    // 快捷指令弹窗确认
    const instructionsAddOk = ()=>{
        if (instructionsContent === '') {
            message.warning('指令内容不能为空')
            return
        }
        if (modalTitle === '新增快捷指令') {
            let list = {
                key: (currentInstructionsList[currentInstructionsList.length - 1]?.key + 1) + '',
                name: instructionsName,
                value: instructionsContent,
                delay: delayTime,
                repeat: repeatSecond,
                isHex: false,
            }
            setCurrentInstructionsLis([
                ...currentInstructionsList,
                list
            ])
            dataList.forEach((v, i)=>{
              if (v.name === currentGroup) {
                dataList[i].childList = [
                    ...currentInstructionsList,
                    list
                ]
              }
            })

        } else {
            let list = currentInstructionsList
            list[currentIndex].name = instructionsName
            list[currentIndex].value = instructionsContent
            list[currentIndex].delay = delayTime
            list[currentIndex].repeat = repeatSecond
            setCurrentInstructionsLis([ ...list ])
            // dataList.forEach((v, i)=>{
            //   if (v.name === currentGroup) {
            //     dataList[i].childList[currentIndex].name = instructionsName
            //     dataList[i].childList[currentIndex].value = instructionsContent
            //     dataList[i].childList[currentIndex].delay = delayTime
            //     dataList[i].childList[currentIndex].repeat = repeatSecond
            //   }
            // })

        }
        localStorage.setItem('shortcutInstructionsList', JSON.stringify(dataList))
        setInstructionsOpen(false)
        setInstructionsName('')
        setInstructionsContent('')
        setDelayTime(1000)
        setRepeatSecond(1) 
    }

    // 快捷指令弹窗取消
    const instructionsACancel = ()=>{
        setInstructionsOpen(false)
        setInstructionsName('')
        setInstructionsContent('')
        setDelayTime(1000)
        setRepeatSecond(1)
    }

    // 指令修改弹窗指令名称修改
    const instructionsNameChange = (e)=>{
        setInstructionsName(e.target.value)
    }

    // 指令修改弹窗指令内容修改
    const instructionsContentChange = (e)=>{
        setInstructionsContent(e.target.value)
    }

    // 指令修改弹窗延迟时间修改
    const delayTimeChange = (e)=>{
      setDelayTime(parseInt(e.target.value))
    }
    
    // 指令修改弹窗重复次数修改
    const repeatChange = (e)=>{
      setRepeatSecond(parseInt(e.target.value))
    }

    // 新增分组弹窗确认
    const groupAddOk = ()=>{
        if (addGroupInput !== '') {
            dataList.push({
                name: addGroupInput,
                childList: []
            })
            setGroupList([
                ...groupList,
                {
                    value: addGroupInput,
                    label: addGroupInput
                }
            ])
            console.log('00', dataList)
            localStorage.setItem('shortcutInstructionsList', JSON.stringify(dataList))
            setGroupModalOpen(false)
            setAddGroupInput('')
        }
    }

    // 新增分组弹窗取消
    const groupACancel = ()=>{
        setGroupModalOpen(false)
        setAddGroupInput('')
    }

    // 新增分组名称输入
    const addGroupName = (e)=>{
        setAddGroupInput(e.target.value)
    }

    // 重命名分组弹窗确认
    const groupRenameOk = ()=>{
        const list = groupList
        groupList.forEach((v, i)=>{
          if (v.label === currentGroup) {
            list[i].label = addGroupInput
            list[i].value = addGroupInput
          }
        })
        dataList.forEach((v, i)=>{
            if (v.name === currentGroup) {
                v.name = addGroupInput
            }
        })
        setGroupList([ ...list ])
        setCurrentGroup(addGroupInput)
        localStorage.setItem('shortcutInstructionsList', JSON.stringify(dataList))
        setGroupRenameOpen(false)
        setAddGroupInput('')
    }

    // 重命名分组弹窗取消
    const groupRenameCancel = ()=>{
        setGroupRenameOpen(false)
        setAddGroupInput('')
    }

    // 重命名分组名称输入
    const renameGroupName = (e)=>{
        setAddGroupInput(e.target.value)
    }

    // 删除分组弹窗确认
    const groupDelOk= ()=>{
        const list = groupList
        groupList.forEach((v, i)=>{
          if (v.value === currentGroup) {
            list.splice(i, 1)
          }
        })
        dataList.forEach((v, i)=>{
            if (v.name === currentGroup) {
                dataList.splice(i, 1)
            }
        })
        setGroupList([ ...list ])
        setCurrentGroup('默认分组')
        setCurrentInstructionsLis(dataList[0].childList)
        localStorage.setItem('shortcutInstructionsList', JSON.stringify(dataList))
        setGroupDelOpen(false)
    }

    // 删除分组弹窗取消
    const groupDelCancel = ()=>{
        setGroupDelOpen(false)
    }

    //
    const loopSendChange = ()=>{
      //函数体
    }

    const saveGroup = ()=>{
        message.warning('功能开发中，敬请期待！')
    }

    return(
        <div className='ShortcutInstructions'>
            <div className='instructionsGroup'>
                <span className='instructionsGroup-name'>选择分组</span>
                <Select
                    defaultValue='默认分组'
                    value={currentGroup}
                    placeholder="请选择分组"
                    style={{width: '140px', marginRight: '10px'}}
                    onChange={groupChange}
                    options={groupList}
                />
                <Radio.Group style={{marginTop: '10px'}}>
                    <Radio.Button value="add" onClick={addGroup}>新增</Radio.Button>
                    <Radio.Button value="rename" onClick={renameGroup}>重命名</Radio.Button>
                    <Radio.Button value="del" onClick={delGroup}>删除</Radio.Button>
                    <Radio.Button value="save" onClick={saveGroup}>保存</Radio.Button>
                </Radio.Group>  
            </div>
            <div className='instructionsBtn'>
                <Button type='primary' onClick={batchSend} style={{ backgroundColor: isbatchSend ? 'red' : ''}}>{isbatchSend ? '停止发送' : '批量发送'}</Button>
                <span style={{marginRight: '5px', marginLeft: '10px'}}>延时时间(ms)</span>
                <Input  type="number" value={delayTime} defaultValue="1000" min="1" style={{width: '100px', marginRight: '20px'}} onChange={delayTimeChange}/>
                <Checkbox onChange={loopSendChange}>循环发送</Checkbox>
                <Button className='btn-add' icon={<PlusOutlined />} onClick={addinstructionsList}/>
            </div>
            {/* 快捷指令列表 */}
            <div className='listBox'>
                <ConfigProvider
                    theme={{
                        components: {
                            Table: {
                                cellPaddingInline: 5,
                                cellPaddingBlock: 13,
                                colorBgContainer:'#f3f5f7',
                                borderColor: '#dee2e6'
                            }
                        },
                    }}
                >
                    <Table
                        rowSelection={{
                        type: 'checkbox',
                        ...rowSelection,
                        }}
                        columns={columns}
                        dataSource={currentInstructionsList}
                        pagination={false}
                        scroll={{
                            y: 630,
                        }}
                        cellPaddingInlineSM
                    />
                </ConfigProvider>
            </div>
            {/* 新增分组弹窗  */}
            <Modal title={modalTitle} cancelText='取消' okText='确定' destroyOnClose='true' open={instructionsOpen} onOk={instructionsAddOk} onCancel={instructionsACancel}>
                <span style={{marginRight: '5px'}}>指令名称</span>
                <Input placeholder="不输入默认为发送" value={instructionsName} style={{width: '200px', marginRight: '200px', marginBottom: '20px', marginTop:'10px'}} onChange={instructionsNameChange}/>
                <span style={{marginRight: '5px'}}>指令内容</span>
                <Input placeholder="请输入指令内容" value={instructionsContent} style={{width: '200px', marginRight: '200px', marginBottom: '20px'}} onChange={instructionsContentChange}/>
                <span style={{marginRight: '5px'}}>延时时间(ms)</span>
                <Input  type="number" value={delayTime} defaultValue="1000" min="1" style={{width: '100px', marginRight: '20px'}} onChange={delayTimeChange}/>
                <span style={{marginRight: '5px'}}>重复次数</span>
                <Input  type="number" value={repeatSecond} defaultValue="1" min="1" style={{width: '100px', marginRight: '100px'}} onChange={repeatChange}/>
            </Modal>
            {/* 新增分组弹窗  */}
            <Modal title="新增高亮快捷分组" cancelText='取消' okText='确定' destroyOnClose='true' open={groupModalOpen} onOk={groupAddOk} onCancel={groupACancel}>
                <span style={{marginRight: '5px'}}>分组名称</span>
                <Input placeholder="请输入新分组名称" style={{width: '185px', marginRight: '10px'}} onChange={addGroupName}/>
            </Modal>
            {/* 重命名分组弹窗  */}
            <Modal title="重命名高亮快捷分组" cancelText='取消' okText='确定' destroyOnClose='true' open={groupRenameOpen} onOk={groupRenameOk} onCancel={groupRenameCancel}>
                <span style={{marginRight: '5px'}}>分组名称</span>
                <Input placeholder="请输入新分组名称" value={addGroupInput} style={{width: '185px', marginRight: '10px'}} onChange={renameGroupName}/>
            </Modal>
            {/* 删除分组弹窗  */}
            <Modal title="删除高亮快捷分组" cancelText='取消' okText='确定' destroyOnClose='true' open={groupDelOpen} onOk={groupDelOk} onCancel={groupDelCancel}>
                <span>是否删除【 {currentGroup} 】分组</span>
            </Modal>
        </div>
    )
}

export default ShortcutInstructions