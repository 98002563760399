import React, { useState, useEffect } from 'react'
import './CloudsView.css'
import { Col, Row, Select, Button, Input, Radio, Checkbox, message, Tabs, Modal, Divider} from 'antd';
import mqtt from 'mqtt';
import { getDeviceData } from '../../services/api';




// const connectUrl = `wss://emqxtest.lggs.tech:38883/mqtt`;


// const client = mqtt.connect(connectUrl, {
//   clean: true,
//   connectTimeout: 4000,
//   reconnectPeriod: 1000,
//   clientId: 'emqx_test',
//   username: 'mqttx',
//   password: 'gscb@2020',
//   ca: '../../certs/emqxtest/my_root_ca.pem', // 替换为您的根证书路径
//   cert: '../../certs/emqxtest/client.pem', // 替换为您的客户端证书路径
//   key: '../../certs/emqxtest/client.key', // 替换为您的客户端私钥路径
//   rejectUnauthorized: false
// })


let asciidecoder = new TextDecoder()

// mqtt路径
const connectUrl = `wss://emqxtest.lggs.tech:8084/mqtt`;

const options = {
    // host: 'mqtts://emqxtest.lggs.tech', // 替换为您的MQTT代理地址
    // port: 38883, // MQTT代理端口（通常是8883）
    // protocol: 'mqtt',
    username: 'mqttx', // 替换为您的MQTT账号
    password: 'gscb@2020', // 替换为您的MQTT密码 
    clientId: 'your_client_id', // 客户端ID
    protocolVersion: 5,
    // ca: `-----BEGIN CERTIFICATE-----
    // MIIDdzCCAl+gAwIBAgIJAI2IvvV7bkHBMA0GCSqGSIb3DQEBCwUAMFIxCzAJBgNV
    // BAYTAkNOMRAwDgYDVQQIDAdCZWlqaW5nMRQwEgYDVQQHDAtTaGlqaW5nc2hhbjEb
    // MBkGA1UEAwwSZW1xeHRlc3QubGdncy50ZWNoMB4XDTI0MDEyMjEyMDEwM1oXDTM0
    // MDExOTEyMDEwM1owUjELMAkGA1UEBhMCQ04xEDAOBgNVBAgMB0JlaWppbmcxFDAS
    // BgNVBAcMC1NoaWppbmdzaGFuMRswGQYDVQQDDBJlbXF4dGVzdC5sZ2dzLnRlY2gw
    // ggEiMA0GCSqGSIb3DQEBAQUAA4IBDwAwggEKAoIBAQC3H3GCI9LT6796Ge5ESP78
    // sdMIUTYYu0frMkboaqUFr7ZA5wy3HRMiXzA2/z8Cp37skSdvHqapSAmWbf60xMr3
    // +DPeKF3QVPf1zkrNKvaM1gpvUkFTh5y63IkB1YFWM5DWEW6pAJr1VhU1dchDqqoY
    // Yj3iodjRDFwCI0WRfhguRD1LCFqHEAfR8EuFGHFnQZst8FIyCIHtXtaUP4cmpi3d
    // IZCj4j4tJ/KAnnW+hEFY4F+qHIfFNdLEOFw6PhOt44Gj6a8j4qpzK6IfYAGvXIFv
    // Id0FyXd1vvCtA645nOT8UGfhdW9GxlL6T1A0n+VlQY4jJy0N6CNjmFXbYVr4KHub
    // AgMBAAGjUDBOMB0GA1UdDgQWBBT99XCCQeedlpdJ3q74ocfmfPYfKjAfBgNVHSME
    // GDAWgBT99XCCQeedlpdJ3q74ocfmfPYfKjAMBgNVHRMEBTADAQH/MA0GCSqGSIb3
    // DQEBCwUAA4IBAQCoprPkRQ6mFFkgBsJRAJPI0UPbh7zNGJWGdWFvPkqITed/dZ2X
    // adiAjT/6FF4cknCMtqQCgRR0SnIYk/HTBIYrg7Bpz/Aos4dZELrKmPpeCMefwOvA
    // 0vCbXIGXuFGLaGPSilsIaRbN+3Nr/ZZRB1Y3ms65ZsXij4KPAMx5YA5M18abAvNe
    // ThxL8aGGQPfxQ+ytZFRfiYVNe8nCcZ1Tybe30F74qZfGbXv54DUziEYE1h5lZhon
    // QrOSlRzS8y783qThT7q/FDRyyt3lMVJeTDx4ma1UdlXQsdh2KrGGD6Kh/IHke7/b
    // HVxLgnKJuvtMGPTuz+i48Xf8FeWFzgjRpOV1
    // -----END CERTIFICATE----- 
    // `, // 替换为您的根证书路径
    // cert: `-----BEGIN CERTIFICATE-----
    // MIIDIzCCAgsCCQCIvVk2mhQC+jANBgkqhkiG9w0BAQsFADBSMQswCQYDVQQGEwJD
    // TjEQMA4GA1UECAwHQmVpamluZzEUMBIGA1UEBwwLU2hpamluZ3NoYW4xGzAZBgNV
    // BAMMEmVtcXh0ZXN0LmxnZ3MudGVjaDAeFw0yNDAxMjIxMjAxNDVaFw0zNDAxMTkx
    // MjAxNDVaMFUxCzAJBgNVBAYTAkNOMRAwDgYDVQQIDAdCZWlqaW5nMRQwEgYDVQQH
    // DAtTaGlqaW5nc2hhbjENMAsGA1UECgwERU1RWDEPMA0GA1UEAwwGY2xpZW50MIIB
    // IjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA1P5z6m0sxRMLYRM72ZX0mekM
    // AKk1br/Wid+rFmzLRdbqXyX4grnBju9l3nrPj/SuTmw8hGD0+rXUCZF+gy7kBqRD
    // SyEHVJWYRFH8/LwnJlRWb8SIcLDiHYzqZ3E0/vkgPLNqVgIU0IuOX1bsKvImH1qD
    // UEOWxgOATv461JAqSlGOQ79hV4nZ7Uc0TQD+rKF53gzanINgmSNI0PsKBvFDNgOd
    // ia3ceJvECSUEBuKEZ9gCUrLt3wp9/MTIcrAK2+Pyq2FveSew3RYjGu7SzF9xdSvx
    // OCZDSJgPUG18JXoHxZrTsD5jRCPADUFmJ996KB7rrdIzDxjVlZJ6545YGyyZ6wID
    // AQABMA0GCSqGSIb3DQEBCwUAA4IBAQAlq4dDxBKVrDRHEWSIVn+5j7duf6sczlfK
    // iALpocD7UFKlNPLOY/CVa/1n5EKzJtw2YrAr0NbWwlDV2MhI+qsGPBor/gK4HLRL
    // I+1R/VkTY6IBDtLBokIt815LhPQITgXI/dmMUdyMWikiNI6qjX11uOKOd3JdRPGT
    // W6PUFpohtFyxiFmCSIK7/2ZThAD8fdF9PvU0qGsARU0n/5hrc06E4u1wIpYUhXfr
    // +r4a/Bb/PCsn/y5hSHHvZ3nSKLhhct6YbPrEafe0uKjFrFnWK2F8CdIi3j2vi/FK
    // FghrxljYHD1Uc7RIvoLgC1nNHFdu2RMTySVT4+K+ng/HV9OP8atz
    // -----END CERTIFICATE-----
    // `, // 替换为您的客户端证书路径
    // key: `-----BEGIN RSA PRIVATE KEY-----
    // MIIEpAIBAAKCAQEA1P5z6m0sxRMLYRM72ZX0mekMAKk1br/Wid+rFmzLRdbqXyX4
    // grnBju9l3nrPj/SuTmw8hGD0+rXUCZF+gy7kBqRDSyEHVJWYRFH8/LwnJlRWb8SI
    // cLDiHYzqZ3E0/vkgPLNqVgIU0IuOX1bsKvImH1qDUEOWxgOATv461JAqSlGOQ79h
    // V4nZ7Uc0TQD+rKF53gzanINgmSNI0PsKBvFDNgOdia3ceJvECSUEBuKEZ9gCUrLt
    // 3wp9/MTIcrAK2+Pyq2FveSew3RYjGu7SzF9xdSvxOCZDSJgPUG18JXoHxZrTsD5j
    // RCPADUFmJ996KB7rrdIzDxjVlZJ6545YGyyZ6wIDAQABAoIBAQCBy2HV7JvIqJdZ
    // m7/wE0qQdAzFZlfSWoMhV7TJoJiqwZHnU5tCBnKmaMqaLhrIbeG+loS2YuNG2IET
    // J5+Bz5RTW8xKPyFZcSPuFkxlsz7kQ6a8y3Ydvhy8xz+cs8cIzcXQV8EoEnKetzyW
    // asDWVuPWXXEB+34owr9w48nz+LpcuIOFifmlaX/UJhkNAYBZtUuvR5I0YuWr5bkA
    // rTavW6Gcg2jWjugriFEophmToYRi6mOkqwL8qOp+rT+3zamrlaveT7GWOVuliKaI
    // vvlswqkLbz+VVhMAHKWjaBE1BCu1cAjB45yHlEJW5V+oQQVjLZo3dsc2MmNApFyO
    // 73E8kJEpAoGBAPHICeCwQFJCCYJmHo5ogcqj/+rZ5p/iSFIJqlTgVQYTy+Fc8RVt
    // hoatdSf4Iy60wjcA2njeTdeSh0lTz5abFEByltFJj6lXT88xjsqxsauxC4LJ1mNn
    // DNeufm3vKBwf/F4tfjoRLOB/n732dgJ3x1NjywSN+DDz/pxlpQpVVq8nAoGBAOGF
    // Bq3HLtSStcR/NeOoyKVLOLbj3gN7sAegzvVjnpxZKjhnEifDLYRF9H9ZoSmOjjED
    // +LO4qzhS/7UF1ICuRjqQj7JsUSqmjDG2EmXcJ3gtfnRc54W9trEzHjKligX+tUi8
    // mXD3HVjgSp+jTwJLBjWzk6RKQnkCoQIybRgiELmdAoGAZNs3E50yF54obVa/5FO/
    // 4JHszUC8LXGtDVuuy90QWoqjf4zPnEql1URCHLwroO4SHfOjLsw82HHtMS9e5sol
    // 9oRWWVNANDmRtzJLCE57eM1Pqe0cGzb/anEFmEItDx5gncCIaRyFBdj2/7zxmTHx
    // 2d1E8M9v+qutR/w2jkru7/0CgYAzepo7sOwPui2j3SYAAddLZBBeL0XOpb1ZAGw9
    // t6/NIM1ycpTCXttVBmL5bwxK4C+1IKL044KyKMAWIm6PsTh2xekHYZJlmf8Gmfk5
    // JzgbRsdG6K4w6U16xaBvDzqig7H+GI8uKjqYvxpJto2iiF2yhHt/VTQ3S1JVng0p
    // JR2MQQKBgQC6uwH3KGzhJX5KVjE0xv3P/opE7MHCqp40Kb/QFQIVp68grRHlCuKH
    // Re5qRkHdjbMSTy9908o4lhnewlZ5UZvgqpCuDm0NS0gbR/6Z+LaHj6NhhbvlXxFH
    // fa5QEXZPu0l1vTBr3PHykVU5FObG3dbe+DN2yZPkTj+DtiYxyrPfgA==
    // -----END RSA PRIVATE KEY-----
    // `, // 替换为您的客户端私钥路径
    // rejectUnauthorized: false
}

const client = mqtt.connect(connectUrl, options);

// 需要订阅的主题
const topic = '/v2/console/DTU/';

function CloudsView (props) {

    const { addCloudLog } = props

    const [ deviceList, setDeviceList ] = useState([])
    const [ ListOpen, setListOpen ] = useState(false)
    const [ selevtDevice, setSelevtDevice ] = useState()
    const [ uploadOpen, setUploadOpen ] = useState(false)

    const selectChange = (value)=>{
      console.log('tt', value)
      setSelevtDevice(value)
    }

    // 获取设备列表
    const getDevice = ()=>{
        getDeviceData().then((res)=>{
            console.log('res', res)
            if (res.errorCode === "OK") {
                let list = []
                res.data.forEach((item)=>{
                  list.push({
                    label: item.code,
                    value: item.code
                  })
                })
                setDeviceList([ ...list ])
            }
        })
    }

    useEffect(()=>{

        getDevice()
        

        //成功连接后触发的回调
        client.on('connect', () => {
            console.log('已经连接成功');
            // 这里可以订阅多个主题
            // client.subscribe([topic], () => {
            // console.log(`订阅了主题 ${topic}`)
            // })
        });
    
    
        // 当客户端收到一个发布过来的消息时触发回调
        client.on('message', function (topic, message, packet) {
            // 这里有可能拿到的数据格式是Uint8Array格式，所以可以直接用toString转成字符串
            // let data = JSON.parse(message.toString());
            console.log("返回的数据22：", message)
            let dataLog = asciidecoder.decode(Uint8Array.from(message))
            let dataAscii = JSON.parse(dataLog)
            console.log('1111', dataLog, JSON.parse(dataLog))
            console.log(dataAscii.data.ts)
            addCloudLog(dataAscii.data.log, dataAscii.data.ts)
        });

        // 连接断开后触发的回调
        client.on("close", function () {
            console.log("已断开连接")
        });

            
            

            // 当连接成功时
            // client.on('connect', () => {
            //     console.log('已经连接成功');
                
            // });

                            // // 订阅主题
                            // client.subscribe('your/topic');

            
            // 当接收到消息时
            // client.on('message', (topic, message) => {
            //     console.log(`Received message on topic ${topic}: ${message.toString()}`);
            //     // 在这里处理接收到的消息
            // });
            


            // const ws = new WebSocket("wss://39.98.127.211:8084");

            // ws.onmessage = (e)=>{
            //   console.log('-----', e)
            // }

    

    }, [])



    const getDeviceList = ()=>{

      getDevice()

    }

    const opneCloud = ()=>{

        if (!selevtDevice) {
           message.error('请先选择设备')
           return
        }

        if (!ListOpen) {

            // 发送信息给 topic（主题）
            client.subscribe([`${topic + selevtDevice}`], () => {
                console.log(`订阅了主题 ${topic  + selevtDevice}`)
            })

            setListOpen(true)
            
        } else {
            client.unsubscribe([`${topic + selevtDevice}`], () => {
                console.log(`取消订阅 ${topic  + selevtDevice}`)
            })

            // client.end();
            setListOpen(false) 
        }

    }

    const uploadChange = ()=>{
      
        setUploadOpen(!uploadOpen)
    }


    return(
        <div className='CloudsView'>
            <div className='deviceList'>
                <span>设备列表</span>
                <Button className='deviceBtn' onClick={getDeviceList}>重新获取</Button>
            </div>
            <Select 
                    // defaultValue={} 
                    style={{ width: '200px', marginBottom: '20px' }}
                    onChange={selectChange} 
                    disabled={ListOpen}
                    options={deviceList}
                />
            <div className="device-status" style={{ backgroundColor: selevtDevice ? '#d1e7dd' : '#f8d7da' }}>{selevtDevice ? selevtDevice : '未选择设备'}</div>
            <Button  onClick={opneCloud}>{ListOpen ? '停止接收' : '开始接收'}</Button>
            <div style={{ height: '20px'}}></div>
            <Divider />
            <Button onClick={uploadChange}>{uploadOpen ? '停止上传' : '上传日志'}</Button> 
        </div>
    )
}

export default CloudsView