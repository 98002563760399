
export function calculateCRC16Modbus(dataHexString) {
	const dataBytes = [];
	for (let i = 0; i < dataHexString.length; i += 2) {
		dataBytes.push(parseInt(dataHexString.substr(i, 2), 16));
	}

    let crc = 0xFFFF;
    const polynomial = 0xA001;  // This is the polynomial x^16 + x^15 + x^2 + 1
    
    for (const byte of dataBytes) {
        crc ^= byte;
        for (let i = 0; i < 8; i++) {
            if (crc & 0x0001) {
                crc = ((crc >> 1) ^ polynomial) & 0xFFFF;
            } else {
                crc >>= 1;
            }
        }
    }
    
    return crc.toString(16).toUpperCase();
}

// export function crc16(data) {var crc = 0xFFFF;
//   var polynomial = 0x1021;
//   for (var i = 0; i < data.length; i++) {
//     crc ^= data.charCodeAt(i) << 8;
//     for (var j = 0; j < 8; j++) {
//       if (crc &amp; 0x8000) {
//         crc = (crc << 1) ^ polynomial;
//       } else {
//         crc <<= 1;
//       }
//     }
//   }
//   return crc &amp; 0xFFFF;
// }

// export function calculateCRC16Modbus(data) {
//     let crcValue = 0xFFFF;
//     for (let i = 0; i < data.length; i++) {
//         crcValue ^= data[i] & 0xFFFF
//         for (let j = 0; j < 8; j++) {
//             if (crcValue & 0x0001) {
//                 crcValue >>= 1
//                 crcValue ^= 0xA001
//             } else {
//                 crcValue >>= 1
//             }
//         }
//     }
 
//     crcValue = crcValue.toString(16)
//     let crcArr = new Array(2)
//     crcArr[0] = crcValue.substring(2, 4)
//     crcArr[1] = crcValue.substring(0, 2)
//     return crcArr
// }
