import './App.css';
import WebConsole from './pages/webConsole/webConsole.jsx';
import { HashRouter, Link, Routes, Route, Navigate } from "react-router-dom"
import routes from './routes/router.js';

function App() {
  return (
    <HashRouter>
      <div className="App">
        {/* <WebConsole></WebConsole> */}
        <Routes>
        {
            routes.map((route , key) => {
              // return <Route key={index} path={item.path} component={item.component}></Route>
                // if(route.exact){
                //   return <Route exact key={key} path={route.path} 
                //   render={
                //     props => (
                //       <route.component {...props} routes={route.routes} />
                //     )
                //   } />
                // }else{
                  return <Route exact path={route.path} element={<route.component />} />
                  // return <Route key={key} path={route.path} render={
                  //   props => (
                  //     <route.component {...props} routes={route.routes} />
                  //   )
                  // } />
                // }
            })
        }
        </Routes>
      </div>
    </HashRouter>
  );
}

export default App;
