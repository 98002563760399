export const colorList = [
    {
        label: '红色',
        value: '#FF0000',
    },
    {
        label: '蓝色',
        value: '#0000FF',
    },
    {
        label: '绿色',
        value: '#008000',
    },
    {
        label: '黄色',
        value: '#cccc02',
    },
    {
        label: '青色',
        value: '#00FFFF',
    },
    {
        label: '深灰色',
        value: '#A9A9A9',
    },
    {
        label: '棕色',
        value: '#A52A2A',
    },
    {
        label: '橙色',
        value: '#FFA500',
    },
    {
        label: '粉红色',
        value: '#FFC0CB',
    },
    {
        label: '紫色',
        value: '#800080',
    },
    {
        label: '洋红色',
        value: '#FF00FF',
    },
    {
        label: '蔚蓝',
        value: '#70f3ff',
    },
]