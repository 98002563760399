import Home from '../pages/webConsole/webConsole.jsx'
import User from '../pages/user/user.jsx'


let routes = [
    {
        path: '/',
        component:Home,
    },
    {
        path:'/Home',
        component:Home,
        routes:[
            // {
            //     path:'/Home/Info',
            //     component:Info
            // },
            // {
            //     path:'/Home/Main',
            //     component:Main
            // },
        ]
    },
    {
        path:'/user',
        component:User
    },
]

export default routes