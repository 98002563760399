//当前时间
export function formatDate(now) {
    const year = now.getFullYear()
    const month = now.getMonth() + 1
    const date = now.getDate()
    const hour = now.getHours() < 10 ? '0' + now.getHours() : now.getHours()
    const minute = now.getMinutes() < 10 ? '0' + now.getMinutes() : now.getMinutes()
    const second = now.getSeconds() < 10 ? '0' + now.getSeconds() : now.getSeconds()
    const millisecond = ('00' + now.getMilliseconds()).slice(-3)
    // return `【${year}-${month}-${date} ${hour}:${minute}:${second}.${millisecond}】`
    return `[ ${hour}:${minute}:${second}.${millisecond} ]`
}