import React, { useState } from 'react'
import { Select, Button, Input, Checkbox, Radio, message, Modal} from 'antd';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { colorList } from '../../common/colorList'
import { highlightColorList } from '../../common/highlightColor'
import './Highlight.css'


let keyHighLight = '' //高亮关键字
let colHighLight = '' //高亮颜色
const colorHighLight = [
    {value: 'red', label: '红色'},
    {value: 'crc16ModBus', label: '黄色'},
    {value: 'crc16ModBus', label: '绿色'}
] //可选择高亮颜色
const groupOption = [
    {value: '', label: '默认分组'}
]
const higGroupColorList = localStorage.getItem('higColorGroupList') ? JSON.parse(localStorage.getItem('higColorGroupList')) : highlightColorList
const gList = []
higGroupColorList.forEach((v, i)=>{
    gList.push({
        value: v.name,
        label: v.name
    })
})

// let currentGroup = '默认分组' // 当前分组
// let addGroupInput = '' // 新增分组名字

function Highlight ({highlightFun}) {

    const [ addGroupInput, setAddGroupInput] = useState('') // 新增分组名字
    const [ groupList, setGroupList ] = useState(gList) // 快捷高亮分组
    const [ currentGroup, setCurrentGroup ] = useState('默认分组') // 当前分组
    const [ colorAddList, setColorAddList ] = useState([]) //新增color列表
    const [ currentColorList, setCurrentColorList ] = useState(higGroupColorList[0].childList) // 当前高亮快捷列表
    const [groupModalOpen, setGroupModalOpen] = useState(false); //新增分组弹窗
    const [groupDelOpen, setGroupDelOpen] = useState(false); //删除分组弹窗
    const [ groupRenameOpen, setGroupRenameOpen] = useState(false); //重命名分组弹窗

    // 高亮关键字
    const keyWordChange = (e)=>{
        keyHighLight = e.target.value
    }

    // 选择高亮颜色
    const colorChange = (e)=>{
        colHighLight = e
    }

    // 是否高亮
    const heightChange = (e)=>{
        highlightFun([{ highlightkey: keyHighLight, color: colHighLight }], e.target.checked)
        console.log('keyWord', e.target.checked)
    }

    const heightListChange = (e, item, index)=>{
        const list = currentColorList
        // list.forEach((v)=>{
        //   v.checked = false
        // })
        list[index].checked = e.target.checked
        let newList = list.filter(i => i.checked)
        console.log('lllllll', list, newList)
        highlightFun(newList, newList.length)
        setCurrentColorList([ ...list ])
    }

    // 选择分组
    const groupChange = (e)=>{
        setCurrentGroup(e)
        let list = []
        higGroupColorList.forEach((v, i)=>{
          if (v.name === e) {
            list = v.childList
          }
        })
        list.forEach((v)=>{
          v.checked = false
        })
        console.log('8888', higGroupColorList)
        setCurrentColorList([ ...list ])
    }

    // 新增分组
    const addGroup = ()=>{
        setGroupModalOpen(true)
    }
     
    // 重命名分组
    const renameGroup = ()=>{
        if (currentGroup === '默认分组') {
            message.warning('默认分组不可重命名')
        } else {
            setAddGroupInput(currentGroup)
            setGroupRenameOpen(true)
        }
    }

    // 删除分组
    const delGroup = ()=>{
        if (currentGroup === '默认分组') {
            message.warning('默认分组不可删除')
        } else {
            setGroupDelOpen(true)
        }
    }

    // 删除当前快捷高亮卡片
    const delCurrList = (index)=>{
        const list = currentColorList
        list.splice(index, 1)
        setCurrentColorList([ ...list ])
        higGroupColorList.forEach((v, i)=>{
            if (v.name === currentGroup) {
              higGroupColorList[i].childList = list
            }
        })
        localStorage.setItem('higColorGroupList', JSON.stringify(higGroupColorList))
    }

    // 新增快捷高亮卡片
    const addColorList = ()=>{
        setColorAddList([
            ...colorAddList,
            {
                id: '',
                highlightkey: '',
                colorName: '',
                color: '',
                checked: false,
            }
        ])
    }

    // 保存新增快捷高亮卡片
    const saveAddList = (index)=>{
        const list = colorAddList
        let card = list[index]
        if(card.highlightkey === ''){
            message.warning('请输入关键字')
        } else if (card.colorName === '') {
            message.warning('请选择高亮颜色')
        } else {
            setCurrentColorList([
                ...currentColorList,
                list[index]
            ])
            higGroupColorList.forEach((v, i)=>{
              if (v.name === currentGroup) {
                higGroupColorList[i].childList = [
                    ...currentColorList,
                    list[index]
                ]
              }
            })
            console.log('-=-=-====++++', higGroupColorList)
            localStorage.setItem('higColorGroupList', JSON.stringify(higGroupColorList))
            delAddList(index)
        }
    }

    // 删除新增快捷高亮卡片
    const delAddList = (index)=>{
        const list = colorAddList
        list.splice(index, 1)
        setColorAddList([ ...list ])
    }

    // 新增快捷高亮卡片关键字输入
    const addListChange = (e, o, v)=>{
        const list = colorAddList
        list[v].highlightkey = e.target.value
        setColorAddList([ ...list ])
    }

    // 新增快捷高亮卡片颜色选择
    const addColorChange = (e, o, v)=>{
        const list = colorAddList
        list[v].colorName = o.label
        list[v].color = e
        setColorAddList([ ...list ])
    }

    // 新增分组名称输入
    const addGroupName = (e)=>{
        setAddGroupInput(e.target.value)
    }

    // 新增分组弹窗确认
    const groupAddOk = ()=>{
        if (addGroupInput !== '') {
            higGroupColorList.push({
                name: addGroupInput,
                childList: []
            })
            setGroupList([
                ...groupList,
                {
                    value: addGroupInput,
                    label: addGroupInput
                }
            ])
            console.log('00', higGroupColorList)
            localStorage.setItem('higColorGroupList', JSON.stringify(higGroupColorList))
            setGroupModalOpen(false)
            setAddGroupInput('')
        }
    }

    // 新增分组弹窗取消
    const groupACancel = ()=>{
        setGroupModalOpen(false)
        setAddGroupInput('')
    }

    // 删除分组弹窗确认
    const groupDelOk = ()=>{
        const list = groupList
        groupList.forEach((v, i)=>{
          if (v.value === currentGroup) {
            list.splice(i, 1)
          }
        })
        higGroupColorList.forEach((v, i)=>{
            if (v.name === currentGroup) {
                higGroupColorList.splice(i, 1)
            }
        })
        setGroupList([ ...list ])
        setCurrentGroup('默认分组')
        setCurrentColorList(higGroupColorList[0].childList)
        localStorage.setItem('higColorGroupList', JSON.stringify(higGroupColorList))
        setGroupDelOpen(false)
    }

    // 删除分组弹窗取消
    const groupDelCancel = ()=>{
        setGroupDelOpen(false)
    }

    // 重命名分组名称输入
    const renameGroupName = (e)=>{
        setAddGroupInput(e.target.value)
    }

    // 重命名分组弹窗确认
    const groupRenameOk = ()=>{
        const list = groupList
        groupList.forEach((v, i)=>{
          if (v.label === currentGroup) {
            list[i].label = addGroupInput
            list[i].value = addGroupInput
          }
        })
        higGroupColorList.forEach((v, i)=>{
            if (v.name === currentGroup) {
                v.name = addGroupInput
            }
        })
        setGroupList([ ...list ])
        setCurrentGroup(addGroupInput)
        localStorage.setItem('higColorGroupList', JSON.stringify(higGroupColorList))
        setGroupRenameOpen(false)
        setAddGroupInput('')
    }

    // 重命名分组弹窗取消
    const groupRenameCancel = ()=>{
        setGroupRenameOpen(false)
        setAddGroupInput('')
    }

    const saveGroup = ()=>{
        message.warning('功能开发中，敬请期待！')
    }

    return(
        <div className='Highlight'>
            <div className='Highlight-top'>
                <Input placeholder="高亮关键字" style={{width: '120px', marginRight: '10px'}} onChange={keyWordChange}/>
                <Select 
                    placeholder="可选择高亮颜色"
                    style={{width: '180px', marginRight: '10px'}}
                    onChange={colorChange}
                    options={colorList}
                />
                <Checkbox onChange={heightChange}  >是否高亮</Checkbox>  
            </div>
            <div className='colorGroup'>
                <span className='colorGroup-name'>选择分组</span>
                <Select
                    defaultValue='默认分组'
                    value={currentGroup}
                    placeholder="请选择分组"
                    style={{width: '140px', marginRight: '10px'}}
                    onChange={groupChange}
                    options={groupList }
                />
                <Radio.Group style={{marginTop: '10px'}}>
                    <Radio.Button value="automaticScroll" onClick={addGroup}>新增</Radio.Button>
                    <Radio.Button value="emptyLog" onClick={renameGroup}>重命名</Radio.Button>
                    <Radio.Button value="copyLog" onClick={delGroup}>删除</Radio.Button>
                    <Radio.Button value="save" onClick={saveGroup}>保存</Radio.Button>
                </Radio.Group>  
            </div>
            <div className='colorListName'>
                <span className='name-key'>高亮关键字</span>
                {/* <span className='name-color'>高亮颜色</span> */}
                <Button className='name-add' icon={<PlusOutlined />} onClick={addColorList}/>
            </div>
            <div className='colorList'>
                {
                    currentColorList.map((item, index)=>{
                      return (
                        <div className='colorCard' key={index}>
                            <Checkbox checked={item.checked} onChange={ (value) => heightListChange(value, item, index) }></Checkbox>
                            <span className='color-name' style={{ backgroundColor: item.color }}>{item.highlightkey}</span>
                            {/* <div className='color-col'>
                                <span className='color-txt'>{item.colorName}</span>
                                <div className='color-show' style={{ backgroundColor: item.color }}></div>
                            </div> */}
                            <Button icon={<CloseOutlined />} onClick={ ()=> delCurrList(index) }/>
                        </div>
                      )
                    })
                }
                {/* <div className='colorCard'>
                    <span className='color-name'>warning</span>
                    <div className='color-col'>
                        <span className='color-txt'>蓝色</span>
                        <div className='color-show' style={{ backgroundColor: '#0000FF' }}></div>
                    </div>
                    <Checkbox onChange={heightChange}>是否高亮</Checkbox>
                    <Button icon={<CloseOutlined />} />
                </div> */}
                {
                    colorAddList.map((item, index)=>{
                      return(
                        <div className='colorCard' key={index}>
                            <Input placeholder="高亮关键字" value={item.highlightkey} style={{width: '185px', marginRight: '10px'}} onChange={(value, option) => addListChange(value, option, index)}/>
                            <Select 
                                placeholder="可选择高亮颜色"
                                style={{width: '114px', marginRight: '10px'}}
                                onChange={ (value, option) => addColorChange(value, option, index) }
                                options={colorList}
                            />
                            <Button style={{ marginRight: '10px'}} onClick={ ()=> saveAddList(index) }>保存</Button>
                            <Button icon={<CloseOutlined />} onClick={ ()=> delAddList(index) }/>
                        </div>
                          )
                    })
                }
            </div>
            {/* 新增分组弹窗  */}
            <Modal title="新增高亮快捷分组" cancelText='取消' okText='确定' destroyOnClose='true' open={groupModalOpen} onOk={groupAddOk} onCancel={groupACancel}>
                <span style={{marginRight: '5px'}}>分组名称</span>
                <Input placeholder="请输入新分组名称" style={{width: '185px', marginRight: '10px'}} onChange={addGroupName}/>
            </Modal>
            {/* 重命名分组弹窗  */}
            <Modal title="重命名高亮快捷分组" cancelText='取消' okText='确定' destroyOnClose='true' open={groupRenameOpen} onOk={groupRenameOk} onCancel={groupRenameCancel}>
                <span style={{marginRight: '5px'}}>分组名称</span>
                <Input placeholder="请输入新分组名称" value={addGroupInput} style={{width: '185px', marginRight: '10px'}} onChange={renameGroupName}/>
            </Modal>
            {/* 删除分组弹窗  */}
            <Modal title="删除高亮快捷分组" cancelText='取消' okText='确定' destroyOnClose='true' open={groupDelOpen} onOk={groupDelOk} onCancel={groupDelCancel}>
                <span>是否删除【 {currentGroup} 】分组</span>
            </Modal>
        </div>
    )

}

export default Highlight